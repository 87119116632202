.cs-main-container {
  border-radius: 6px;
}
.cs-message--outgoing .cs-message__content {
  background-color: #e5e7eb !important;
}

.cs-message--incoming .cs-message__content {
  background-color: #15abe1 !important;
  color: #fff !important;
}

.cs-message-input__content-editor-wrapper {
  background-color: #f3f4f6 !important;
}
.cs-message-input__content-editor-container {
  background-color: #f3f4f6 !important;
}
.cs-message-input__content-editor {
  background-color: #f3f4f6 !important;
}
.scrollbar-container {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 10px;
}
.cs-button {
  color: #15abe1;
}
